<template>
  <div>
    <h3 ref="ca">
      Matrix Addition
    </h3>
    <p>
      Matrices can be added together, subtracted from each other, and multiplied with each other. In this lesson, we will learn how to add two matrices.
    </p>
    <h3 ref="what-can-be-added">
      Condition for Matrix Addition
    </h3>
    <p>
      Two matrices may only be added if they have exactly the same layout, i.e.,
      the number of rows must match between the matrices to be added
      and the number of columns must also match. The resultant matrix has the same number
      of rows and columns as the input matrices.
      For example &mdash; consider the following two matrices.
      $$
      \mathbf{A} = \begin{bmatrix}
      1 & 4 \\
      -1 & 1 \\
      \end{bmatrix}
      \qquad
      \mathbf{B} = \begin{bmatrix}
      10 & 2 \\
      5 & 7 \\
      \end{bmatrix}
      $$
      These matrices can be added together since they have same number of rows and columns. <br>
      Next, consider the following two matrices.
      $$
      \mathbf{C} = \begin{bmatrix}
      7 & 6 & 1 \\
      4 & 1 & 8 \\
      \end{bmatrix}
      \qquad
      \mathbf{D} = \begin{bmatrix}
      3 & 2 \\
      7 & 1 \\
      3 & -11 \\
      \end{bmatrix}
      $$
      These matrices, while they are valid on their own, cannot be added
      together because they don't have the same number of rows and columns.
    </p>
    <h3 ref="addition-example">
      Matrices Addition Example
    </h3>
    <p>
      In order to add matrices together, we look at the corresponding elements
      of each matrix and add those together to produce the result.

      Let's add matrix \( \mathbf{A} \) and \( \mathbf{B} \) together to form
      the matrix \( \mathbf{S} \). In other words,

      $$
      \mathbf{S} = \mathbf{A} + \mathbf{B}
      $$

      Let's start from the top-left. The top-left component of matrix \( \mathbf{A} \) is
      \( A_{11} \). The top-left component of matrix \( \mathbf{B} \) is \( B_{11} \). The top-left
      component of the resultant matrix, \( S_{11} \), is simply the sum of the top-left
      components of \( \mathbf{A} \) and \( \mathbf{B} \):

      $$
      S_{11} = A_{11} + B_{11}
      $$

      This is true for each component (so the top-right component of \(
      \mathbf{S} \) is simply the sum of the top-right components of \(
      \mathbf{A} \) and \( \mathbf{B} \) and so on).

      Therefore the sum is:
      $$
      \begin{aligned}
      \mathbf{S} &= \mathbf{A} + \mathbf{B} \\
      \mathbf{S} &= \begin{bmatrix}
      1 & 4 \\
      -1 & 1 \\
      \end{bmatrix}
      +
      \begin{bmatrix}
      10 & 2 \\
      5 & 7 \\
      \end{bmatrix} \\
      \mathbf{S} &= \begin{bmatrix}
      1 + 10 & 4 + 2 \\
      -1 + 5 & 1 + 7 \\
      \end{bmatrix} \\
      \mathbf{S} &= \begin{bmatrix}
      11 & 6  \\
      4 & 8 \\
      \end{bmatrix} \\
      \end{aligned}
      $$
    </p><p>
      <br>
    </p><h3 ref="magicgraph">
      MagicGraph &vert; Matrix Addition
    </h3>
    <p>
      This MagicGraph offers a visually interactive illustration that explains how matrix addition works.
      You are given two matrices \(\mathbf A\) and \(\mathbf B\). These matrices have the same number of rows and the same number of columns.
      Matrix \(\mathbf M\) is the sum of matrices \(\mathbf A\) and \(\mathbf B\).
    </p>
    <h5> To get started </h5>
    <ul class="a">
      <li>Hover your mouse over the number within the brackets on the rightmost matrix.</li>
      <li>Look at the pop-up labels to see how to form this element from the input matrices on the left.</li>
      <li>Hit shuffle to get a new matrix to explore!</li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <h3 ref="magicgraph">
      MagicGraph &vert; Test Your Knowledge
    </h3>
    <p>
      This MagicGraph offers a visually interactive exercise to test your knowledge of matrix addition.
      You are given two matrices \(\mathbf A\) and \(\mathbf B\).
      These matrices have the same number of rows and the same number of columns.
      Your goal is to determine matrix \(\mathbf M\) which is the sum of matrices \(\mathbf A\) and \(\mathbf B\).
    </p>
    <h5> To get started </h5>
    <ul class="a">
      <li>Fill in the sum of the two matrices on the left into the blue input boxes on the right.</li>
      <li>Check your answer with the red circular button. A green checkmark indicates the right answer, while a red X means you should try again. You can try as many times as you want.</li>
      <li>Hit shuffle to get a new practice problem!</li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox2" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js';
export default {
  name: 'Angles',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    const title = 'Matrix Addition';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    const newTopics = [
      // {title: 'Index Notation', img:'/assets/number-1.svg', action: () => this.goto('index-notation')},
      {title: 'What can be added?', img:'/assets/number-1.svg', action: () => this.goto('what-can-be-added')},
      {title: 'Addition Example', img:'/assets/number-2.svg', action: () => this.goto('addition-example')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('magicgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1a();
    Boxes.box1b();
  },
  metaInfo() {
  return{ title: 'Matrix Addition',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
