import {
    makeResponsive,
    placeTitle,
    clearInputFields,
    setInputFieldAnswerColor,
} from '../../../../common/edliy_utils';
import {
    getRandomInt,
    createRandomFriendlyMatrixWithSize,
    drawMatrix,
    drawMatrixBrackets,
} from '../matrix_utils';

function Madd(matrix1, matrix2) {
    let matrix = [];
    let r = 0;
    for (r = 0; r < matrix1.length; ++r) {
        let c = 0;
        matrix.push([]);
        for (c = 0; c < matrix1[r].length; ++c) {
            let value = matrix1[r][c] + matrix2[r][c];
            matrix[r].push(value);
        }
    }
    return matrix;
}

class MatrixAdditionExploration {
    constructor(board) {
        this.board = board;
        this.objectList = [];
    }

    register = (object) => {
        this.objectList.push(object);
    };

    deleteProblem = () => {
        this.board.suspendUpdate();
        this.board.removeObject(this.objectList);
        this.objectList = [];
        this.board.unsuspendUpdate();
    };

    makeProblem = () => {
        const nrow = getRandomInt(2, 3);
        const ncol = getRandomInt(2, 3);

        let b = this.board;

        let matrixComponentLabel = b.create('text', [0, 2.25, '(Component)'],
            {
                visible: false,
                anchorX: 'middle', highlight:false, CssStyle: 'fontFamily:Oswald', fontSize: function () { return Math.round(18 * b.canvasWidth / 500.) }, fixed: true
            }
        );
        this.register(matrixComponentLabel);

        const scale = 1.25;
        const matrixNameLabelProperties = {
            anchorX: 'middle',
            display: 'internal',
            CssStyle: 'fontFamily:Oswald',
            highlight:false,
            fontSize: () => Math.round(22 * b.canvasWidth / 500.),
            fixed: true,
        };

        const centerOffset = scale * (ncol - 1) / 2.0;

        this.register(b.create('text', [-7 + centerOffset, 9, 'A'], matrixNameLabelProperties));
        this.register(b.create('text', [-7 + ncol * scale + 2.0 + centerOffset, 9, 'B'], matrixNameLabelProperties));
        this.register(b.create('text', [-7 + 2 * ncol * scale + 4.0 + centerOffset, 9, 'M'], matrixNameLabelProperties));

        let matrix1 = createRandomFriendlyMatrixWithSize(nrow, ncol);
        let matrix2 = createRandomFriendlyMatrixWithSize(nrow, ncol);
        let matrixSum = Madd(matrix1, matrix2);

        this.register(b.create('image', ['/assets/plus.svg', [-7 + ncol * 1.25, 7 - nrow * 0.5 * 1], [1, 1]], { visible: true, fixed: true }));
        this.register(b.create('image', ['/assets/equal.svg', [-5 + 2 * ncol * 1.25, 7 - nrow * 0.5 * 1], [1, 1]], { visible: true, fixed: true }));
        const [matrix1Objects, matrix1Labels] = drawMatrix(this.board, [-7, 7], scale, matrix1);
        const [matrix2Objects, matrix2Labels] = drawMatrix(this.board, [-7 + ncol * scale + 2.0, 7], scale, matrix2);

        this.register(matrix1Objects);
        this.register(matrix1Labels);
        this.register(matrix2Objects);
        this.register(matrix2Labels);

        let lastHoverR = null;
        let lastHoverC = null;
        const updateComponent = function (m, r, c, mLabel) {
            const highLightColor = '#984ea3';
            const highlightFontAttributes = {
                CssStyle: 'font-weight: bold',
                color: highLightColor,
            };

            // In a new element; update the text as appropriate.
            if (c != lastHoverC || r != lastHoverR) {
                b.suspendUpdate();
                lastHoverR = r;
                lastHoverC = c;
                matrixComponentLabel.setText(
                    `
            A_{<span style='color: red'>${String(r + 1)}</span><span style='color: green'>${String(c + 1)}} + </span>B_{<span style='color: red'>${String(r + 1)}</span><span style='color: green'>${String(c + 1)}}</span> = M_{<span style='color: red'>${String(r + 1)}</span><span style='color: green'>${String(c + 1)}</span>}<br>
            <span style='color: ${highLightColor}'>${matrix1[r][c]}</span> +
            <span style='color: ${highLightColor}'>${matrix2[r][c]}</span> =
            <span style='color: ${highLightColor}'>${m[r][c]}</span>
            `);
                matrixComponentLabel.setAttribute({ visible: true });
                matrix1Labels[r][c].setAttribute(highlightFontAttributes);
                matrix2Labels[r][c].setAttribute(highlightFontAttributes);
                mLabel.setAttribute(highlightFontAttributes);
                b.unsuspendUpdate();
            };
        }
        //
        const clearComponent = function (m, r, c, mLabel) {
            const clearFontAttributes = {
                CssStyle: 'font-weight: normal',
                color: 'gray',
            };

            b.suspendUpdate();
            matrix1Labels[r][c].setAttribute(clearFontAttributes);
            matrix2Labels[r][c].setAttribute(clearFontAttributes);
            mLabel.setAttribute(clearFontAttributes);

            // Not in a new element now, safe to clear label.
            if (c == lastHoverC && r == lastHoverR) {
                lastHoverR = null;
                lastHoverC = null;
                matrixComponentLabel.setAttribute({ visible: false });
            }
            b.unsuspendUpdate();
        }
        //
        const [matrixSumObjects, matrixSumLabels] = drawMatrix(b, [-7 + 2 * ncol * scale + 4.0, 7], scale, matrixSum,
            updateComponent, clearComponent);

        this.register(matrixSumObjects);
        this.register(matrixSumLabels);
        this.board.unsuspendUpdate();
    }

    oneTimeSetup = () => {
        this.board.options.layer['image'] = 14;
        placeTitle(this.board, 'Addition of Two Matrices', '(Hover Over a Component to See its Composition)');
        let shuffle = this.board.create('image', ['/assets/shuffle.svg', [-0.75, -1.75], [2, 2]], { visible: true, fixed: true });
        shuffle.setLabel('Tap to Shuffle!')
        shuffle.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
        shuffle.on('over', function () {this.label.setAttribute({visible:true});});
        shuffle.on('out', function () {this.label.setAttribute({visible:false});});
        // Avoid "losing this" issue when called in a different context.
        let shuffleFunction = () => {
            this.deleteProblem();
            this.makeProblem();
        };
        shuffle.on('down', shuffleFunction);
    };
}
class MatrixAdditionAssessment {
    constructor(board) {
        this.board = board;
        this.objectList = [];
        this.inputs = [];
        this.answers = [];
    }

    register = (object) => {
        this.objectList.push(object);
    };

    deleteProblem = () => {
        this.board.suspendUpdate();
        this.board.removeObject(this.objectList);
        this.objectList = [];
        clearInputFields(this.board);
        this.board.unsuspendUpdate();
    };

    checkInputs = () => {

        const nRows = this.inputs.length;
        const nCols = this.inputs[0].length;
        let result = true;
        for (let i = 0; i < nRows; ++i) {
            for (let j = 0; j < nCols; ++j) {
                // let labelElem = this.inputs[i][j].rendNodeLabel;
                // if (labelElem) {
                //     labelElem.style.display = 'none';
                // }

                const isCorrect = (this.inputs[i][j].Value() == this.answers[i][j]);
                setInputFieldAnswerColor(this.inputs[i][j], isCorrect);
                if (!isCorrect) {
                    result = false;
                }
            }
        }
        return result;
    }

    makeProblem = () => {
        const nrow = getRandomInt(2, 3);
        // Restrict assessment module to 2 columns to keep the layout reasonable.
        const ncol = 2;

        let b = this.board;

        let matrixComponentLabel = b.create('text', [0, 2.25, '(Component)'],
            {
                visible: false,
                highlight:false,
                anchorX: 'middle', CssStyle: 'fontFamily:Oswald', fontSize: function () { return Math.round(18 * b.canvasWidth / 500.) }, fixed: true
            }
        );
        this.register(matrixComponentLabel);

        const scale = 1.25;
        const matrixNameLabelProperties = {
            anchorX: 'middle',
            display: 'internal',
            highlight:false,
            CssStyle: 'fontFamily:Oswald',
            fontSize: () => Math.round(22 * b.canvasWidth / 500.),
            fixed: true,
        };

        const centerOffset = scale * (ncol - 1) / 2.0;
        const McenterOffset = scale * (2 * ncol - 1) / 2.0;

        this.register(b.create('text', [-7 + centerOffset, 9, 'A'], matrixNameLabelProperties));
        this.register(b.create('text', [-7 + ncol * scale + 2.0 + centerOffset, 9, 'B'], matrixNameLabelProperties));
        this.register(b.create('text', [-7 + 2 * ncol * scale + 4.0 + McenterOffset, 9, 'M'], matrixNameLabelProperties));

        let matrix1 = createRandomFriendlyMatrixWithSize(nrow, ncol);
        let matrix2 = createRandomFriendlyMatrixWithSize(nrow, ncol);
        this.answers = Madd(matrix1, matrix2);

        this.register(b.create('image', ['/assets/plus.svg', [-7 + ncol * 1.25, 7 - nrow * 0.5 * 1], [1, 1]], { visible: true, fixed: true }));
        this.register(b.create('image', ['/assets/equal.svg', [-5 + 2 * ncol * 1.25, 7 - nrow * 0.5 * 1], [1, 1]], { visible: true, fixed: true }));
        const [matrix1Objects, matrix1Labels] = drawMatrix(this.board, [-7, 7], scale, matrix1);
        const [matrix2Objects, matrix2Labels] = drawMatrix(this.board, [-7 + ncol * scale + 2.0, 7], scale, matrix2);

        this.register(matrix1Objects);
        this.register(matrix1Labels);
        this.register(matrix2Objects);
        this.register(matrix2Labels);

        // Make this 2x as wide to allow for bigger input boxes>
        this.board.suspendUpdate();
        const [matrixSumObjects, ] = drawMatrixBrackets(b, [-7 + 2 * ncol * scale + 4.0, 7], scale, nrow, 2*ncol, false);

        this.inputs = [];
        for (let i = 0; i < nrow; ++i) {
            this.inputs[i] = [];
            for (let j = 0; j < ncol; ++j) {
                const x = -7 + 2 * ncol * scale + 4.0 + (2 * j * scale);
                const y = 7 - i * scale;
                let input = b.create('input', [x, y, '', ''],
                    {
                        cssStyle: 'fontFamily:Oswald; width:2em; background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;',
                        // label: { display: 'internal' },
                        withLabel: false,
                        fontSize: () => Math.round(16 * b.canvasWidth / 500.),
                        fixed: true,
                        parse: true,
                    });
                this.inputs[i][j] = input;
                this.register(input);
            }
        }

        this.register(matrixSumObjects);
        this.board.unsuspendUpdate();
    }

    oneTimeSetup = () => {
        this.board.options.layer['image'] = 14;
        placeTitle(this.board, 'Addition of Two Matrices', '(Calculate & Enter the Components of Matrix M in the Blue Boxes)');
        let shuffle = this.board.create('image', ['/assets/shuffle.svg', [-0.75, -1.75], [2., 2.]], { visible: true, fixed: true });
        shuffle.setLabel('Tap to Shuffle!')
        shuffle.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
        shuffle.on('over', function () {this.label.setAttribute({visible:true});});
        shuffle.on('out', function () {this.label.setAttribute({visible:false});});
        const assessmentScale = 1.5;
        let ok1 = this.board.create('image', ['/assets/check.svg',
            [
                8.0 - assessmentScale / 2.0,
                6.0 - assessmentScale / 2.0
            ],
            [
                assessmentScale,
                assessmentScale
            ]
        ],
            { visible: false, fixed: true });
        let cross1 = this.board.create('image',
            ['/assets/cross.svg',
                [
                    8.0 - assessmentScale / 2.0,
                    6.0- assessmentScale / 2.0
                ],
                [
                    assessmentScale,
                    assessmentScale
                ]
            ],
            { visible: false, fixed: true });

        let tryMe = this.board.create('image',
            [
                '/assets/test.svg',
                [
                    6 - assessmentScale / 2.0,
                    -1.25 - assessmentScale / 2.0
                ],
                [
                    1.5*assessmentScale,
                    1.5*assessmentScale
                ]
            ],
            {
                visible: true,
                fixed: true
            }
        );
        tryMe.setLabel('Tap to Check Your Answer')
        tryMe.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
        tryMe.on('over', function () {this.label.setAttribute({visible:true});});
        tryMe.on('out', function () {this.label.setAttribute({visible:false});});
        // Avoid "losing this" issue when called in a different context.
        let shuffleFunction = () => {
            this.deleteProblem();
            this.makeProblem();
            ok1.setAttribute({ visible: false });
            cross1.setAttribute({ visible: false });
        };
        shuffle.on('down', shuffleFunction);


        let check = () =>  {
            if (this.checkInputs()) {
                ok1.setAttribute({ visible: true });
                cross1.setAttribute({ visible: false });

            }
            else {
                ok1.setAttribute({ visible: false });
                cross1.setAttribute({ visible: true });
            }
        };

        tryMe.on('down', check);
    };
}

const Boxes = {
    box1a: function () {
        let brd1 = JXG.JSXGraph.initBoard('jxgbox1', {
            boundingbox: [-10, 15, 10, -5],
            keepaspectratio: true, axis: false, ticks: { visible: false },
            grid: true, showCopyright: false, showNavigation: false,
            pan: { enabled: false }, zoom: { enabled: false }
        });
        brd1.suspendUpdate();
        makeResponsive(brd1);
        let m = new MatrixAdditionExploration(brd1);
        m.oneTimeSetup();
        m.makeProblem();
    },

    box1b: function () {
        let brd1 = JXG.JSXGraph.initBoard('jxgbox2', {
            boundingbox: [-10, 15, 10, -5],
            keepaspectratio: true, axis: false, ticks: { visible: false },
            grid: true, showCopyright: false, showNavigation: false,
            pan: { enabled: false }, zoom: { enabled: false }
        });
        brd1.suspendUpdate();
        makeResponsive(brd1);
        let m = new MatrixAdditionAssessment(brd1);
        m.oneTimeSetup();
        m.makeProblem();
    },
}
export default Boxes;
